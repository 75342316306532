import React, { useEffect } from 'react';

import './cropper.scss';
import './styles.scss';
import { TitleLine, Breadcrumbs, BreadcrumbsItem } from '@ui';
import { EditPassword, ProfileEditForm } from '@features/user/ui';
import { push } from '@features/app/navigation.model';
import { useProfileTdLk } from '../../features/api/hooks';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/profile/',
    breadcrumb: 'Профиль',
  },
  {
    path: '/lk/profile/edit',
    breadcrumb: 'Редактировать',
  },
];

export const ProfileEditPage = () => {
  const { profile, load } = useProfileTdLk();

  useEffect(() => {
    load();
  }, [load]);

  const handleProfileEditFormSuccess = () => {
    push({ path: '/lk/profile' });
  };

  return (
    <React.Fragment>
      <TitleLine className="titleline--profile-edit">
        <Breadcrumbs props={breadcrumbs} />
        {!profile?.without_pass && (
          <EditPassword
            login={profile?.phone}
            type="edit"
            triggerClassName="titleline__button"
          />
        )}
      </TitleLine>
      {profile && (
        <ProfileEditForm
          // @ts-ignore
          defaultValues={profile}
          onSuccess={handleProfileEditFormSuccess}
        />
      )}
    </React.Fragment>
  );
};
