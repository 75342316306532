import { Task } from '@eus/react-client/src/types/tasks';
import { push } from '@features/app/navigation.model';
import { GetConsultationForm } from '@features/consultations/ui';
import { HOST_CRM_API } from '@teledoctor/common/dist/config/api';
import { CreateFormValuesWithCardClientId as CreateFormValues } from '@teledoctor/common/dist/features/consultations/forms/create';
import { Breadcrumbs, BreadcrumbsItem, TitleLine } from '@ui';

import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import { useSchemaTdLk } from '../../features/api';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/tasks/',
    breadcrumb: 'Консультации',
  },
  {
    path: '/lk/tasks/get',
    breadcrumb: 'Получить консультацию',
  },
];

export interface GetConsultationRouterProps {
  cardClientId: number;
  serviceId?: string;
  relatedTaskId?: number;
  isClientCare?: boolean;
}

export const GetConsultationPage = () => {
  const { state: routerState } = useLocation<GetConsultationRouterProps>();

  const { cardClientId, serviceId, relatedTaskId, isClientCare } =
    routerState ?? {};

  const formDefaultValues: CreateFormValues = {
    card_client_id: cardClientId,
    service_id: (serviceId && Number(serviceId)) || undefined,
    files: [],
    description: '',
  };

  const { state, load, reset } = useSchemaTdLk();

  const loadSchema = useCallback(
    (serviceId: number) =>
      load(serviceId).then((schema) => console.log('schema', schema)),
    [load],
  );

  useEffect(() => {
    if (serviceId) {
      loadSchema(Number(serviceId));
    }
  }, [serviceId, loadSchema]);

  const schema = useMemo(() => {
    return state.schema?.map((field) => {
      if (field.type === 'datetime') {
        return {
          ...field,
          baseURL: HOST_CRM_API,
          dateResource: 'tasks/video-dates',
          timeResource: 'tasks/video-schedule',
        };
      }

      if (field.field === 'description') {
        return { ...field, min: 3 };
      }

      if (field.field === 'integrated_dentistry[year]') {
        return {
          ...field,
          type: 'year',
        };
      }

      if (field.field === 'integrated_dentistry[phone]') {
        return {
          ...field,
          type: 'phone',
        };
      }
      return field;
    });
  }, [state.schema]);

  const onTaskCreated = useCallback((task: Task) => {
    push({
      path: `/lk/tasks/${task.id}`,
    });
  }, []);

  return (
    <React.Fragment>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>

      <GetConsultationForm
        schema={schema}
        defaultValues={formDefaultValues}
        resetSchema={reset}
        loadSchema={loadSchema}
        isClientCare={isClientCare}
        relatedTaskId={relatedTaskId}
        onFormSubmitted={onTaskCreated}
      />
    </React.Fragment>
  );
};
