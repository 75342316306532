import { AskQuestionsForm } from '@features/questions/ui/forms/ask-questions-form/page';
import { Breadcrumbs, BreadcrumbsItem, TitleLine } from '@ui';
import { FC, useCallback } from 'react';
import formatPhone from '@eus/crm-default-logic/src/formatters/phone';
import { useProfileTdLk } from '../../features/api';
import { Task } from '@eus/react-client/src/types/tasks';
import { push } from '@features/app/navigation.model';

interface AskQuestionProps {}

const BREADCRUMBS_CONFIG: BreadcrumbsItem[] = [
  {
    path: '/lk/tasks/',
    breadcrumb: 'Консультации',
  },
  {
    path: '/lk/tasks/questions/',
    breadcrumb: 'Вопросы по услугам',
  },
  {
    path: '/lk/tasks/questions/ask',
    breadcrumb: 'Задать вопрос',
  },
];

export const AskQuestion: FC<AskQuestionProps> = () => {
  const { profile } = useProfileTdLk();
  const { phone } = profile || {};
  const phoneFormatted = phone ? formatPhone(phone) : undefined;

  const onTaskCreated = useCallback((task: Task) => {
    push({
      path: `/lk/tasks/questions/${task.id}`,
    });
  }, []);

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={BREADCRUMBS_CONFIG} />
      </TitleLine>
      <AskQuestionsForm
        contacts={{ phone: phoneFormatted ?? '' }}
        onFormSubmitted={onTaskCreated}
      />
    </>
  );
};
